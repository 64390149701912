import axios from "axios";

import { instance } from "@/react/hooks/api";
import { createAxiosMutation } from "@/react/hooks/react-query";
import { getErrorMessage } from "@/react/lib/axios-helpers";

export const useUpdateUserPasswordMutation = createAxiosMutation(
  async ({
    userId,
    currentPassword,
    newPassword,
    confirmPassword,
  }: {
    userId: number;
    currentPassword: string;
    newPassword: string;
    confirmPassword: string;
  }) => {
    const { data } = await instance().patch(`/users/${userId}`, {
      current_password: currentPassword,
      password: newPassword,
      password_confirmation: confirmPassword,
    });

    return data;
  },
  {
    successToast: () => ({
      message: "Successfully updated password.",
    }),
    errorToast: (err) => ({
      message: `Error updating password. ${getErrorMessage(err)}`,
    }),
    onSuccess: () => {
      window.location.href = "/login";
    },
  }
);

export const useResetPasswordMutation = createAxiosMutation(
  async ({
    token,
    newPassword,
    confirmPassword,
  }: {
    token: string;
    newPassword: string;
    confirmPassword: string;
  }) => {
    const { data } = await axios.post("/authentication/reset-password", {
      forgot_password_token: token,
      password: newPassword,
      password_confirmation: confirmPassword,
    });

    return data;
  },
  {
    successToast: () => ({
      message: "Successfully changed password.",
    }),
    errorToast: (err) => ({
      message: `Error changing password. ${getErrorMessage(err)}`,
    }),
    onSuccess: () => {
      window.location.href = "/login";
    },
  }
);

export const useCreatePasswordMutation = createAxiosMutation(
  async ({
    token,
    newPassword,
    confirmPassword,
    communityZipcode,
    terms,
  }: {
    token: string;
    newPassword: string;
    confirmPassword: string;
    communityZipcode?: string | null;
    terms: boolean;
  }) => {
    const { data } = await axios.post("/authentication/accept-invite", {
      invitation_token: token,
      password: newPassword,
      password_confirmation: confirmPassword,
      community_zipcode: communityZipcode,
      tos_checkbox: terms ? "on" : "off",
    });

    return data;
  },
  {
    successToast: () => ({
      message:
        "Your account registration is complete. Please sign in to continue.",
    }),
    errorToast: (err) => ({
      message: `${getErrorMessage(err)}`,
    }),
  }
);

export const useVerifyCommunityZipcodeMutation = createAxiosMutation(
  async ({
    token,
    communityZipcode,
  }: {
    token: string;
    communityZipcode: string;
  }) => {
    const { data } = await axios.post(
      "/authentication/verify-community-zipcode",
      { invitation_token: token, community_zipcode: communityZipcode }
    );

    return data;
  },
  {
    successToast: () => ({
      message:
        "Successfully verified unit zip or postal code. Please sign in to continue.",
    }),
    errorToast: (err) => ({
      message: `${getErrorMessage(err)}`,
    }),
  }
);

export const useForgotPassword = createAxiosMutation(async (email: string) => {
  const { data } = await axios.post("/authentication/forgot-password", {
    email,
  });
  return data;
});
