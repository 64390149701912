import { StyleSheet, View } from "react-native";

import {
  FormikPasswordInputField,
  FormikSubmit,
  Typography,
  VStack,
  Link,
  Box,
} from "@smartrent/ui";
import { ChevronLeft } from "@smartrent/icons";

import { useFocus } from "@/react/hooks/useFocus";

const EmailPasswordForm = () => {
  const focusRef = useFocus();
  return (
    <>
      <Box>
        <VStack spacing={16}>
          <Typography style={styles.typography} type="title3">
            Sign in to your Account
          </Typography>
          <FormikPasswordInputField
            name="password"
            label="Password"
            submitOnEnter
            required
            ref={focusRef}
            textInputProps={{
              nativeID: "password",
            }}
            testID="password"
          />
          <FormikSubmit
            label="Sign In"
            submittingLabel="Submitting..."
            size="small"
          />
          <Link style={styles.link} to="/forgot-password">
            Forgot your password?
          </Link>
        </VStack>
      </Box>
      <View>
        <Link type="title" href="/login">
          <View style={styles.startOverChevron}>
            <ChevronLeft size={24} color={"#73b9af"} />
            <Typography
              style={styles.startOverText}
              color="primary"
              type="title6"
            >
              Start Over
            </Typography>
          </View>
        </Link>
      </View>
    </>
  );
};

const styles = StyleSheet.create({
  typography: { textAlign: "center" },
  link: { textAlign: "center", textDecorationLine: "underline" },
  startOverChevron: { flexDirection: "row", alignItems: "center" },
  startOverText: { textDecorationLine: "underline", marginLeft: 4 },
});

export default EmailPasswordForm;
