import { StatusBanner, StatusBannerStatus } from "@smartrent/ui";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

export const UrlStatusMessages = () => {
  const { status, message } = useStatusMessages();

  return status && message ? (
    <StatusBanner
      status={status as StatusBannerStatus}
      title={message}
      numberOfLines={4}
    />
  ) : null;
};

/**
 * This hook is designed to read the query params when the page first loads
 * and never again. The reason is that we're designing the UrlStatusMessages
 * component to remove the query params from the URL after reading them. We
 * don't want this hook to re-read the query params after they've been removed.
 */
const useStatusMessages = () => {
  const history = useHistory();

  const [status, setStatus] = useState(null);
  const [message, setMessage] = useState(null);

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    setStatus(mapQueryStatusToBannerStatus(queryParams.get("status")));
    setMessage(queryParams.get("message"));
    history.replace(window.location.pathname);
    // eslint-disable-next-line react-hooks/exhaustive-deps -- We want this to only run once.
  }, []);

  return {
    status,
    message,
  };
};

function mapQueryStatusToBannerStatus(queryStatus: string | null) {
  switch (queryStatus) {
    case "error":
      return "error";
    default:
      return "knowledge";
  }
}
