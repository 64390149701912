import { RouteComponentProps } from "react-router-dom";
import { Box } from "@smartrent/ui";

import { UserRole } from "@/react/types/users/user";
import { AuthPage } from "@/react/common/AuthPage";

import { ResetForm } from "./ResetForm";
import { ZipValidationForm } from "./ZipValidationForm";

interface SetPasswordProps extends RouteComponentProps<{ token: string }> {
  email?: string | null | undefined;
  role: UserRole;
  set_password: boolean;
  verify_zip: boolean;
}

const SetPassword = ({
  email,
  role,
  set_password,
  verify_zip,
  match,
}: SetPasswordProps) => {
  return (
    <AuthPage>
      <Box>
        {/* We have two booleans to control what gets rendered here which creates
      4 different combos though we only actually want 3:

      1. verify_zip && set_password - Render ResetForm
      2. !verify_zip && set_password - Render ResetForm
      3. verify_zip && !set_password - Render ZipValidationForm

      It doesn't make sense to have !verify_zip and !set_password but it is possible.
      If we see that, we want to render  the ResetForm as a fallback. Thus, we only
      have one state where we want ZipValidationForm so we explicitly look for
      that and render ResetForm in all other cases.*/}
        {verify_zip && !set_password ? (
          <ZipValidationForm />
        ) : (
          <ResetForm
            email={email}
            role={role}
            match={match}
            verifyZip={verify_zip}
          />
        )}
      </Box>
    </AuthPage>
  );
};

export default SetPassword;
