import { Typography } from "@smartrent/ui";

import { UserRole } from "@/react/types/users/user";

import { InstructionStep } from "./InstructionStep";
import { Section } from "./Section";

interface LockInstructionsProps {
  wakeLockUrl: string;
  inputCodeUrl: string;
  submitCodeUrl: string;
  lockingUrl: string;
  userRole: string;
}

const wakingCopy = (lockingUrl: string) => {
  return {
    title: ["KA210", "ADB1"].some((m) => lockingUrl?.includes(m))
      ? "Wake up your lock"
      : "Wake your lock",
    instructions: `To wake up your lock, ${
      ["KA210", "ADB1"].some((m) => lockingUrl?.includes(m))
        ? "cover the face of the keypad by touching it with your palm."
        : "press the Yale Logo, or cover the face of the lock."
    }`,
  };
};

const enteringCopy = (lockingUrl: string) => {
  return {
    title: ["KA210", "ADB1"].some((m) => lockingUrl?.includes(m))
      ? "Enter your pin code"
      : "Enter your code",
    instructions: ["KA210", "ADB1"].some((m) => lockingUrl?.includes(m))
      ? "We emailed and/or texted you this unique code. If you have not received your code, please verify that your contact information is accurate with your community management team."
      : "Entering your code and pressing the check mark will unlock your lock.",
  };
};

const confirmingCopy = (lockingUrl: string, userRole: string) => {
  return {
    title: ["KA210"].some((m) => lockingUrl?.includes(m)) ? (
      "Press #"
    ) : (
      <>
        Press{" "}
        <img
          src={
            userRole === UserRole.HomeOwner
              ? "/images/email/Alloy-checkmark.svg"
              : "/images/email/check-button.jpg"
          }
          style={{ paddingLeft: 8 }}
          alt="Check button on lock"
        />
      </>
    ),
    instructions: ["KA210", "ADB1"].some((m) => lockingUrl?.includes(m))
      ? "The door is unlocked. You’re in!"
      : null,
  };
};

const lockingCopy = (lockingUrl: string) => {
  let instructions =
    "To lock your door when leaving, simply cover the face of the keypad again.";

  if (["yrd216", "yrd210", "yrdntb645"].some((m) => lockingUrl?.includes(m))) {
    instructions = "Press any button on the keypad to lock your door.";
  } else if (["KA210", "ADB1"].some((m) => lockingUrl?.includes(m))) {
    instructions =
      "Hold any digit (1–9 or 0) for one second. The door is locked.";
  }

  return {
    title: ["KA210", "ADB1"].some((m) => lockingUrl?.includes(m))
      ? "To lock"
      : "Locking Your Door",
    instructions,
  };
};

export function LockInstructions({
  wakeLockUrl,
  inputCodeUrl,
  submitCodeUrl,
  lockingUrl = "",
  userRole,
}: LockInstructionsProps) {
  const waking = wakingCopy(lockingUrl);
  const entering = enteringCopy(lockingUrl);
  const confirming = confirmingCopy(lockingUrl, userRole);
  const locking = lockingCopy(lockingUrl);

  return (
    <Section title="Unlocking the Door">
      <Typography>
        Follow the easy, step-by-step process below to let yourself in.
      </Typography>
      <Typography style={{ marginBottom: "60px" }}>
        If you have any issues accessing the unit, please contact the leasing
        office.
      </Typography>

      {wakeLockUrl ? (
        <InstructionStep
          stepNumber={1}
          title={waking.title}
          imageUrl={wakeLockUrl}
          imageTitle="Wake the lock"
          instruction={waking.instructions}
        />
      ) : null}

      <InstructionStep
        stepNumber={wakeLockUrl ? 2 : 1}
        title={entering.title}
        imageUrl={inputCodeUrl}
        imageTitle="Enter the access code"
        instruction={entering.instructions}
      />

      <InstructionStep
        stepNumber={wakeLockUrl ? 3 : 2}
        title={confirming.title}
        imageUrl={submitCodeUrl}
        imageTitle="Press the confirmation key"
        instruction={confirming.instructions}
      />

      {lockingUrl ? (
        <InstructionStep
          stepNumber={
            ["KA210", "ADB1"].some((m) => lockingUrl?.includes(m)) && 4
          }
          title={locking.title}
          imageUrl={lockingUrl}
          imageTitle="Locking your door"
          instruction={locking.instructions}
        />
      ) : null}
    </Section>
  );
}
