import { Box, Typography, VStack } from "@smartrent/ui";
import * as Yup from "yup";
import { FormTextInputField, Form, FormSubmit } from "@smartrent/forms";

import { auth } from "@/lib/links";
import { AuthPage } from "@/react/common/AuthPage";
import { useForgotPassword } from "@/react/queries/mgmt-api/users/password";

const ForgotPassword = () => {
  const [forgotPasswordMutation, { isLoading }] = useForgotPassword();
  return (
    <AuthPage>
      <Box>
        <Form
          initialValues={{
            email: "",
          }}
          validationSchema={Yup.object({
            email: Yup.string().email().required("Please enter your email"),
          })}
          onSubmit={async ({ email }) => {
            await forgotPasswordMutation(email);
            // We have to reload the page because the login form has some state
            // that doesn't reset properly.
            window.location.href = auth.login({
              status: "info",
              message:
                "Password reset instructions have been sent to your email.",
            });
          }}
        >
          <VStack spacing={16}>
            <Typography
              variation="semibold.title.three"
              style={{ textAlign: "center" }}
            >
              Forgot Password
            </Typography>
            <FormTextInputField
              name="email"
              label="Email"
              submitOnEnter
              required
            />
            <FormSubmit disabled={isLoading} />
          </VStack>
        </Form>
      </Box>
    </AuthPage>
  );
};

export default ForgotPassword;
