import { lazy } from "react";
import { Route, Switch, RouteComponentProps } from "react-router-dom";

import createBundle from "@/react/common/CreateBundle";

// Do not lazy load these as they are likely to be landing pages so it's important
// that they're fast to load.
import Login from "@/react/bundles/login/Login";
import LockoutContainer from "@/react/bundles/lockout_troubleshooting/LockoutContainer";
import RoleBasedThemeProvider from "@/react/common/ThemeProviders/RoleBasedThemeProvider";
import SetPassword from "@/react/bundles/set_password/SetPassword";
import ForgotPassword from "@/react/bundles/ForgotPassword";
import { ExpiringLinkContextProvider } from "@/react/bundles/expiring_links/ExpiringLinkContext";
import ExpiringLink from "@/react/bundles/expiring_links/ExpiringLink";
import { ResetPassword } from "@/react/bundles/account/ResetPassword";

const PublicShopRouter = lazy(
  () => import("@/react/routers/unauthenticated/subrouters/publicShop")
);

const PublicMaintenanceWorkOrderOverview = lazy(
  () =>
    import("@/react/bundles/maintenance_work_orders/PublicMaintenanceOverview")
);

const PublicWorkOrders = lazy(
  () => import("@/react/bundles/maintenance_work_orders/PublicWorkOrders")
);

createBundle("unauthenticated", (bundleProps: any) => {
  return (
    <RoleBasedThemeProvider role={bundleProps.user_role} {...bundleProps}>
      <Switch>
        {/* Lockout Troubleshooting */}
        <Route
          path="/lockout*"
          render={(props) => <LockoutContainer {...bundleProps} {...props} />}
        />
        <Route
          path="/login"
          render={(props: RouteComponentProps) => (
            <Login {...bundleProps} {...props} />
          )}
        />
        <Route
          exact
          path="/forgot-password"
          render={() => <ForgotPassword {...bundleProps} />}
        />
        <Route
          exact
          path="/forgot-password/:token"
          render={(props: RouteComponentProps) => (
            <ResetPassword {...bundleProps} {...props} />
          )}
        />
        <Route
          exact
          path="/invite/:token"
          render={(props: RouteComponentProps) => (
            <SetPassword {...bundleProps} {...props} />
          )}
        />
        {/* Temporary Access Codes / Expiring Links */}
        <Route
          path="/p/access-codes/:token"
          render={() => (
            <ExpiringLinkContextProvider {...bundleProps}>
              <ExpiringLink />
            </ExpiringLinkContextProvider>
          )}
        />
        <Route exact path="/mt/:token/" component={PublicWorkOrders} />
        <Route
          exact
          path="/mt/:token/:workOrderId"
          component={PublicMaintenanceWorkOrderOverview}
        />
        <Route
          path="/shop/:orgSlug"
          render={() => <PublicShopRouter {...bundleProps} />}
        />
      </Switch>
    </RoleBasedThemeProvider>
  );
});
