import { Role } from "../Role";
import { Region } from "../Region";
import { Organization } from "../Organization";

export enum UserRole {
  SuperAdmin = "super_admin",
  ImportedEmployee = "imported_employee",
  Employee = "employee",
  HomeOwner = "home_owner",
  Consumer = "consumer",
}

export interface UserFromList {
  accepted_invite: boolean; // this exists on /users list APIs :(
  email: string;
  employee_role?: string; // deprecated, use `employee_role_name` instead
  employee_role_name: null | string;
  first_name: string | null;
  id: number;
  invitation_sent_at: null | string;
  last_name: string | null;
  organization_id: number;
  organization_name: string;
  phone_number?: string | null; // deprecated, use `mobile_phone` instead
  mobile_phone: string | null;
  role: UserRole;
  saml_active: boolean;
  service_account: boolean;
}

export interface User {
  id: number;
  uuid: string;
  email: string;
  first_name: string | null;
  last_name: string | null;
  role: UserRole;
  is_installer: boolean;
  mobile_phone: string | null; // this exists on /users/me
  invitation_accepted: boolean; // this exists on /users/me
  tos_accepted_at: string | null;
  regions: Region[];

  employee_role: Role;
  is_last_org_admin: boolean;
  organization: Pick<Organization, "id" | "name" | "industry" | "is_demo">;
  is_smartrent_staff: boolean;
  in_support_mode: boolean;
  is_support_staff: boolean;
  is_super_admin: boolean;
  service_account?: boolean;
  remote_id: string | null;
  inserted_at: string | null;

  // token used only for zendesk chat authentication
  zendesk_chat_jwt_token: string | null;

  // Token used for the Intercom chat authentication
  intercom_chat_auth_code: string | null;

  // This doesn't exist in the backend as of 5/18/22, but we'll add this in the future and users
  // can opt-in to the new interface. We can start to use this as our hook for showing old UI vs new UI
  enable_beta_ui: boolean;

  // This controls the information modals on the new Vacant Access page
  vacant_access_info_completed: boolean;
}
