import { DeviceType } from "./Device";

export enum DeviceNotificationsEnum {
  ALARM_TYPE_21_LEVEL_1 = "ALARM_TYPE_21_LEVEL_1",
  ALARM_TYPE_21_LEVEL_2 = "ALARM_TYPE_21_LEVEL_2",
  ALARM_TYPE_21_LEVEL_3 = "ALARM_TYPE_21_LEVEL_3",
  ALARM_TYPE_25_LEVEL_1 = "ALARM_TYPE_25_LEVEL_1",
  ALARM_TYPE_22_LEVEL_1 = "ALARM_TYPE_22_LEVEL_1",
  ALARM_TYPE_24_LEVEL_1 = "ALARM_TYPE_24_LEVEL_1",
  ALARM_TYPE_9_LEVEL_0 = "ALARM_TYPE_9_LEVEL_0",
  ALARM_TYPE_9_LEVEL_1 = "ALARM_TYPE_9_LEVEL_1",
  ALARM_TYPE_9_LEVEL_2 = "ALARM_TYPE_9_LEVEL_2",
  CLUTCH_JAMMED_UNLOCKING = "CLUTCH_JAMMED_UNLOCKING",
  CLUTCH_JAMMED_LOCKING = "CLUTCH_JAMMED_LOCKING",
  ALARM_TYPE_43_LEVEL_0 = "ALARM_TYPE_43_LEVEL_0",
  ALARM_TYPE_43_LEVEL_1 = "ALARM_TYPE_43_LEVEL_1",
  ALARM_TYPE_43_LEVEL_2 = "ALARM_TYPE_43_LEVEL_2",
  ALARM_TYPE_112_LEVEL_0 = "ALARM_TYPE_112_LEVEL_0",
  ALARM_TYPE_112_LEVEL_251 = "ALARM_TYPE_112_LEVEL_251",
  ALARM_TYPE_168_LEVEL_30 = "ALARM_TYPE_168_LEVEL_30",
  ALARM_TYPE_168_LEVEL_10 = "ALARM_TYPE_168_LEVEL_10",
  ALARM_TYPE_161_LEVEL_3 = "ALARM_TYPE_161_LEVEL_3",
  ALARM_TYPE_176_LEVEL_255 = "ALARM_TYPE_176_LEVEL_255",
  ALARM_TYPE_176_LEVEL_0 = "ALARM_TYPE_176_LEVEL_0",
  UNLOCK_VIA_RF = "UNLOCK_VIA_RF",
  KEY_OR_THUMBTURN_UNLOCK = "KEY_OR_THUMBTURN_UNLOCK",
  THUMBTURN_UNLOCK = "THUMBTURN_UNLOCK",
  RF_UNLOCK = "RF_UNLOCK",
  KEYPAD_UNLOCK = "KEYPAD_UNLOCK",
  MANUAL_UNLOCK = "MANUAL_UNLOCK",
  LOCK_VIA_RF = "LOCK_VIA_RF",
  KEY_OR_THUMBTURN_LOCK = "KEY_OR_THUMBTURN_LOCK",
  PALM_TOUCH_LOCK = "PALM_TOUCH_LOCK",
  THUMBTURN_LOCK = "THUMBTURN_LOCK",
  INSIDE_BUTTON_LOCK = "INSIDE_BUTTON_LOCK",
  RF_LOCK = "RF_LOCK",
  KEYPAD_LOCK = "KEYPAD_LOCK",
  MANUAL_LOCK = "MANUAL_LOCK",
  MASTER_CODE_CHANGED_RF = "MASTER_CODE_CHANGED_RF",
  MASTER_CODE_CHANGED_KEYPAD = "MASTER_CODE_CHANGED_KEYPAD",
}

export enum DeviceNotificationEnum {
  Notifications = "notifications",
}

export enum DeviceThermostatAttributeEnum {
  Mode = "mode",
  CurrentTemp = "current_temp",
  CurrentHumidity = "current_humidity",
  HeatingSetpoint = "heating_setpoint",
  CoolingSetpoint = "cooling_setpoint",
  FanMode = "fan_mode",
  OperatingState = "operating_state",
}

export enum DeviceRepeaterAttributeEnum {
  CurrentTemp = "current_temp",
  CurrentHumidity = "current_humidity",
}

export enum DeviceLockAttributeEnum {
  Locked = "locked",
}

export enum DeviceBinarySwitchAttributeEnum {
  On = "on",
}

export enum DeviceMultilevelSwitchAttributeEnum {
  Level = "level",
}

export enum DeviceShadeAttributeEnum {
  Level = "level",
}

export enum DeviceDimmerAttributeEnum {
  Level = "level",
}

export enum DeviceSensorAttributeEnum {
  Temperature = "current_temp",
  CurrentHumidity = "current_humidity",
  Motion = "motion_binary",
  Tamper = "tamper",
  Leak = "leak",
  Contact = "contact_broken",
}

export enum DeviceSirenAttributeEnum {
  SirenActive = "siren_active",
}

export enum DeviceGarageDoorAttributeEnum {
  Open = "open",
}

export type $DeviceAttributeName =
  | "mode"
  | "current_temp"
  | "current_humidity"
  | "heating_setpoint"
  | "cooling_setpoint"
  | "fan_mode"
  | "operating_state"
  | "tamper"
  | "user_codes"
  | "locked"
  | "notifications"
  | "on"
  | "level"
  | "open"
  | "siren_active"
  | "leak"
  | "contact_broken"
  | "motion_binary"
  | "battery_level"; // add to this list as it grows

export enum AttributeNameEnum {
  On = "on",
  Off = "off",
  Level = "level",
  Mode = "mode",
  CurrentTemp = "current_temp",
  CurrentHumidity = "current_humidity",
  HeatingSetpoint = "heating_setpoint",
  CoolingSetpoint = "cooling_setpoint",
  FanMode = "fan_mode",
  UserCodes = "user_codes",
  Locked = "locked",
  Notifications = "notifications",
  OperatingState = "operating_state",
  Tamper = "tamper",
  Open = "open",
  SirenActive = "siren_active",
  Leak = "leak",
  ContactBroken = "contact_broken",
  MotionBinary = "motion_binary",
  BatteryLevel = "battery_level",
}

export interface $DeviceAttribute {
  id: number;
  remote_id: null | string;
  last_read_state: null | string;
  name: $DeviceAttributeName;
  type?: DeviceType;
}
