import { StyleSheet, View } from "react-native";
import { useMedia } from "react-use";

/**
 * This component is designed to be used for public authentication pages
 * such as login, forgot password, etc.
 */
export const AuthPage: React.FC = ({ children }) => {
  const isDesktop = useMedia("(min-width: 768px)");
  return (
    <View style={isDesktop ? styles.desktop : styles.mobile}>{children}</View>
  );
};

const styles = StyleSheet.create({
  desktop: {
    maxWidth: 300,
  },
  mobile: {
    width: "100%",
  },
});
